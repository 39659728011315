import React from "react";
import { Routes, Route } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LoadScript } from '@react-google-maps/api';
import TopMenu from "./components/Menus/TopMenu";
import LeftPage from "./components/Pages/LeftPage";
import MapPage from "./components/Pages/MapPage";
import MainPage from "./components/Pages/MainPage";
import AdventurePackagesPage from "./components/Pages/AdventurePackagesPage";
import RidersTalesPage from "./components/Pages/RidersTalesPage";
import RidersGuidePage from "./components/Pages/RidersGuidePage";
import AdvertisePage from './components/Pages/AdvertisePage';
import WeatherPage from './components/Pages/WeatherPage';
import AcademyPage from './components/Pages/AcademyPage';
import Footer from "./components/Footer/Footer";
import './index.css';
import usePersistentState from "./auth/usePersistentState";
import { createTheme } from '@mui/material/styles';
import TripDetailsPage from './components/Pages/TripDetailsPage';
import PrivacyPolicyPage from './components/Pages/Legal/PrivacyPolicyPage';
import CookiePolicyPage from './components/Pages/Legal/CookiePolicyPage';
import TermsOfServicePage from './components/Pages/Legal/TermsOfServicePage';
import SellerOperatorAgreementPage from './components/Pages/Legal/SellerOperatorAgreementPage';
import LegalNoticePage from './components/Pages/Legal/LegalNoticePage';
import RefundCancellationPolicyPage from './components/Pages/Legal/RefundCancellationPolicyPage';

const libraries = ['places'];

const theme = createTheme({
  palette: {
    primary: {
      main: '#646464',
      light: '#7f7e7a',
      dark: '#4a4a4a',
    },
    secondary: {
      main: '#9a9891',
      light: '#b5b3a8',
      dark: '#7f7e7a',
    },
    background: {
      default: '#ffffff',
      paper: '#f5f5f5',
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        "root": {
          "&.Mui-checked": {
            "color": "#646464"
          }
        }
      },
    },
  },
});

const App = () => {
  const [waypoints, setWaypoints] = usePersistentState('waypoints',[]);
  const [routes, setRoutes] = usePersistentState('routes',null);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          minHeight: '100vh' 
        }}>
          <TopMenu />
          <Box component="main" sx={{ flexGrow: 1 }}>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/map" element={
                <Box sx={{ 
                  display: 'flex', 
                  height: 'calc(100vh - 64px - 201px)', // Account for header (64px) and footer (201px - including border)
                  overflow: 'hidden',
                  borderBottom: '1px solid #9a9891' // Add border to match footer
                }}>
                  <LeftPage waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
                  <MapPage waypoints={waypoints} setWaypoints={setWaypoints} routes={routes} setRoutes={setRoutes} />
                </Box>
              } />
              <Route path="/adventure-packages" element={<AdventurePackagesPage />} />
              <Route path="/riders-tales" element={<RidersTalesPage />} />
              <Route path="/riders-guide" element={<RidersGuidePage />} />
              <Route path="/advertise" element={<AdvertisePage />} />
              <Route path="/weather" element={<WeatherPage />} />
              <Route path="/academy" element={<AcademyPage />} />
              <Route path="/trip/:tripId" element={<TripDetailsPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/cookie-policy" element={<CookiePolicyPage />} />
              <Route path="/terms-of-service" element={<TermsOfServicePage />} />
              <Route path="/seller-operator-agreement" element={<SellerOperatorAgreementPage />} />
              <Route path="/legal-notice" element={<LegalNoticePage />} />
              <Route path="/refund-cancellation-policy" element={<RefundCancellationPolicyPage />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </LoadScript>
    </ThemeProvider>
  );
};

export default App;